import { render, staticRenderFns } from "./DsbRuleHistoryComparer.vue?vue&type=template&id=811e8594&scoped=true&"
import script from "./DsbRuleHistoryComparer.vue?vue&type=script&lang=js&"
export * from "./DsbRuleHistoryComparer.vue?vue&type=script&lang=js&"
import style0 from "./DsbRuleHistoryComparer.vue?vue&type=style&index=0&id=811e8594&lang=css&scoped=true&"
import style1 from "./DsbRuleHistoryComparer.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "811e8594",
  null
  
)

export default component.exports