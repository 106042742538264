<template>
  <vbt-tabs
    v-model="currentTab"
    :tabs="tabsConfig"
    :loading="loading"
  >
    <v-layout
      slot="fields"
      column
    >
      <vbt-table
        :headers="fieldsHeaders"
        :items="ruleSliceFields"
        :page-size="ruleSliceFields.length"
      />
    </v-layout>

    <v-layout
      slot="data"
      column
    >
      <vbt-code
        :value="ruleSliceBody"
        max-height="537px"
      />
    </v-layout>
  </vbt-tabs>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { namespace as RulesNamespace, ActionTypes as RulesActionTypes } from '../store.types';

const dataLoadingOptions = {
  getterName: RulesActionTypes.GET_RULE_SLICE,
  parameterize: true,
  searchable: false,
  paged: false,
};

const tabsConfig = Object.freeze([
  { text: 'Fields', key: 'fields' },
  { text: 'Data', key: 'data' },
]);

const fieldsHeaders = Object.freeze([
  { text: 'Name', value: 'name' },
  { text: 'Value', value: 'value' },
]);

export default {
  name: 'DsbRuleSlice',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      currentTab: 'fields',

      tabsConfig,
      fieldsHeaders,
    };
  },

  computed: {
    ...mapState(RulesNamespace, {
      ruleSliceFields: ({ ruleSlice: { fields } }) => (fields || []).map(({ value, name }) => ({
        value: value || '-',
        name,
      })),
      ruleSliceBody: ({ ruleSlice: { body } }) => {
        try {
          return JSON.stringify(JSON.parse(body), null, '\t');
        } catch (e) {
          return '';
        }
      },
    }),
  },

  methods: {
    ...mapActions(RulesNamespace, [RulesActionTypes.GET_RULE_SLICE]),
  },
};
</script>
