<template>
  <vbt-content-box
    :loading="loading"
    title="Compare"
  >
    <template #toolbar>
      <v-layout
        align-center
        justify-end
      >
        <strong style="font-size: 16px;">
          Show only modified
        </strong>

        <v-switch
          v-model="showOnlyModified"
          class="vbt-hide-form-control-details ml-2"
          color="#d47600e6"
        />
      </v-layout>
    </template>

    <v-layout class="mb-3 white--text">
      <strong class="compare-label" />

      <v-flex sm4>
        <strong>
          Before:
        </strong>
      </v-flex>

      <v-flex sm4>
        <strong>
          After:
        </strong>
      </v-flex>
    </v-layout>

    <template v-for="(item, i) in compareItems">
      <v-layout
        v-show="!showOnlyModified || (showOnlyModified && item.modified)"
        :key="item.name"
        class="white--text"
        column
      >
        <v-layout :class="{
        'compare-highlighted': !showOnlyModified && item.modified,
        'py-2': true,
      }">
          <strong class="compare-label">
            {{ item.name }}:
          </strong>

          <v-flex sm4>
            <template v-for="value in item.values">
              <v-checkbox
                v-if="value.name"
                :key="genGUID(value)"
                :input-value="value.old"
                :label="value.name"
                class="vbt-hide-form-control-details white--text"
                disabled
              />

              <strong
                v-else
                :key="genGUID(value)"
              >
                {{ value.old }}
              </strong>
            </template>
          </v-flex>

          <v-flex sm4>
            <template v-for="value in item.values">
              <v-checkbox
                v-if="value.name"
                :key="genGUID(value)"
                :input-value="value.new"
                :label="value.name"
                class="vbt-hide-form-control-details white--text"
                disabled
              />

              <strong
                v-else
                :key="genGUID(value)"
              >
                {{ value.new }}
              </strong>
            </template>
          </v-flex>
        </v-layout>

        <v-divider v-if="i < visibleCompareItemsCount - 1" />
      </v-layout>
    </template>
  </vbt-content-box>
</template>

<script>
import { useDataLoading } from '@mixins/factories';

import { genGUID, wrapToLoadingFn } from '@helpers';

import { RulesApiService } from '../_services/rules.api.service';

const dataLoadingOptions = {
  getterName: 'getRuleCompare',
  parameterize: true,
  searchable: false,
  paged: false,
};
export default {
  name: 'DsbRuleHistoryComparer',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      compareItems: [],

      showOnlyModified: false,
    };
  },

  computed: {
    visibleCompareItemsCount() {
      return (
        this.showOnlyModified
          ? this.compareItems.filter(({ modified }) => modified)
          : this.compareItems
      ).length;
    },
  },

  methods: {
    async getRuleCompare({ suffix, id: ruleId, historyId }) {
      const data = await RulesApiService.getRuleHistoryCompare({
        suffix,
        ruleId,
        historyId,
      });

      this.$set(this, 'compareItems', data);
    },

    genGUID,
    wrapToLoadingFn,
  },
};
</script>

<style lang="css" scoped>
.compare-label {
  display: flex;
  justify-content: flex-end;
  width: 280px;
  margin-right: 20px;
}

.compare-highlighted {
  background-color: #d47600e6;
}
</style>

<style lang="css">
div.v-input__slot .v-label {
  color: #ffffff;
}
</style>
